<template>
    <v-app
        :class="$global.state.filter.search == 'شمس الغري' ? 'shake' : ''"
        class="app"
        :style="{ background: $vuetify.theme.themes[theme].background }"
    >
        <mainlayout />
    </v-app>
</template>

<script>
export default {
    components: {
        Mainlayout: () => import("@/layouts/Mainlayout.vue"),
    },

    computed: {
        theme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        },
    },

    methods: {
        setupNotifications() {
            // var id = this.$store.state.userInfo.userData.id;
            this.$messaging
                .requestPermission()
                .then(() => {
                    return this.$messaging.getToken({
                        vapidKey:
                            "BCk7n3sNeptHSote5-LPS7vZ1ONuy46WjA_jkNoaxv0VjRbDcNzQz3zA_6i2rogOo7kolufsSdKlI9uzhQHnnrI",
                    });
                })
                .then(function (token) {
                    // subscribeTokenToTopic(token, id);
                    subscribeTokenToTopic(token, "adminNotifykf");
                    // // console.log("Token : " + token);
                    localStorage.setItem("fmsToken", token);
                })
                .catch(function () {});

            function subscribeTokenToTopic(token, topic) {
                fetch(
                    `https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`,
                    {
                        method: "POST",
                        headers: new Headers({
                            Authorization: `key=AAAASIzKIWM:APA91bGTIuu6MG3OO5sKTPYCjDTlUEt0uDglhiapVxx_CxdavN2RLe7pll7jvJMeX6cSPdZTzYOceQhRpWN9X2pLyxhzzy4GTXcHwuhHySeEt_3tgznksV-G3hQpwmz-eVNTJx8lhBwj`,
                        }),
                    }
                )
                    .then((response) => {
                        if (response.status < 200 || response.status >= 400) {
                            // // console.log(response.status, response);
                        }
                        // // console.log(`"${topic}" is subscribed`);
                    })
                    .catch((error) => {
                        // console.error(error.result);
                    });
                return true;
            }

            this.$messaging.onMessage((payload) => {
                // // console.log("Message received. ", payload.notification);
                const notificationOption = {
                    body: payload.notification.body,
                    icon: payload.notification.icon,
                    click_action: payload.notification.click_action,
                };

                if (Notification.permission === "granted") {
                    var notification = new Notification(
                        payload.notification.title,
                        notificationOption
                    );
                    notification.onclick = function (ev) {
                        ev.preventDefault();
                        window.open(
                            payload.notification.click_action,
                            "_blank"
                        );
                        notification.close();
                    };
                }
            });

            this.$messaging.onTokenRefresh(() => {
                this.$messaging
                    .getToken()
                    .then(function () {})
                    .catch(function () {});
            });
        },

        unsubscribeFromTopic() {
            this.$messaging
                .unsubscribeFromTopic(
                    localStorage.getItem("fmsToken"),
                    "adminNotifykf"
                )
                .then(function (response) {
                    // See the MessagingTopicManagementResponse reference documentation
                    // for the contents of response.
                     console.log(
                        "Successfully unsubscribed from topic:",
                        response
                    );
                })
                .catch(function (error) {
                     console.log("Error unsubscribing from topic:", error);
                });
        },
    },

    mounted() {
        if (this.$route.name != "login") {
            if (
                Notification.permission !== "denied" ||
                Notification.permission === "default"
            ) {
                try {
                    this.setupNotifications();
                } catch {
                    // console.log("Notifications not supported");
                }
            }
        }

        // this.$eventBus.$on(`unsubscribeFromTopic`, () => {
        //     this.unsubscribeFromTopic();
        // });
        // IntitalizeFireBaseMessaging();
    },

    beforeDestroy() {
        this.$eventBus.$off(`unsubscribeFromTopic`);
    },

    watch: {
        $route: function (to, from) {
            to;
            if (from.name == "login") {
                if (
                    Notification.permission !== "denied" ||
                    Notification.permission === "default"
                ) {
                    try {
                        this.setupNotifications();
                    } catch {
                        // console.log("Notifications not supported");
                    }
                }
            }
        },
    },
    data: () => ({
        messages: [],
        pusher: null,
        channel: null,
    }),
};
</script>

<style lang="scss">
@import "@/style/style.scss";

#app {
    font-family: "Cairo";
}

.shake {
    .btn {
        animation: shakex 0.4s;
        animation-iteration-count: 10;
    }
    .v-card {
        animation: shakex 0.4s;
        animation-iteration-count: 10;
    }
    .shadow-chat {
        animation: shakey 0.4s;
        animation-iteration-count: 10;
    }

    #chatId {
        animation: shakex 0.4s;
        animation-iteration-count: 10;
    }
    .v-toolbar {
        animation: shakex 0.4s;
        animation-iteration-count: 10;
    }
}

@keyframes shakex {
    0% {
        transform: translateX(-5px);
        // transform: skew(0deg);
    }
    50% {
        transform: translate(5px);
        // transform: skew(2deg);
    }
    100% {
        transform: translate(-5px);
        // transform: skew(-2deg);
    }
}

@keyframes shakey {
    0% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(-5px);
    }
}
</style>
