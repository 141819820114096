import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./axios";
import vuetify from "./plugins/vuetify";
import style from "./style/style.scss";
import validations from "@/utils/validations";
import "./utils/filters";
import VueNumeric from "vue-numeric-currency";
import vueDebounce from "vue-debounce";
import VueTheMask from "vue-the-mask";
import global from "./store/global";
import i18n from "@/libs/i18n";
import VueSweetalert2 from "vue-sweetalert2";
import genericService from "@/services/genericService";

Vue.use(VueSweetalert2);

Vue.component("LottieAnimation", () => import("lottie-web-vue"));

import AudioRecorder from "vue-audio-recorder";
Vue.use(AudioRecorder);

Vue.use(VueTheMask);
Vue.prototype.$phoneMask = "+964 ### ### ####";
Vue.prototype.$birthDate = "####/##/##";
Vue.prototype.$money = "#,###.##";
Vue.use(vueDebounce);
Vue.use(VueNumeric);

Vue.prototype.$http = axios;
Vue.prototype.$validations = validations;
Vue.prototype.$global = global;
Vue.prototype.$genericService = genericService;

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

Vue.component("empty", () => import("./components/Empty.vue"));
Vue.component("Loading", () => import("./components/Loading.vue"));
Vue.component("Pagination", () => import("./components/Pagination.vue"));

import firebaseMessaging from "./firebase";
Vue.prototype.$messaging = firebaseMessaging;

//401
axios.interceptors.response.use(
    (response) => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    (error) => {
        // // console.log(error)
        if (error.response.status) {
            if (error.response.status == 401) {
                router.replace({
                    path: "/login",
                    query: { redirect: router.currentRoute.fullPath },
                });
            }
            return Promise.reject(error.response);
        }
    }
);
//401

new Vue({
    router,
    store,
    i18n,
    vuetify,
    style,
    render: (h) => h(App),
}).$mount("#app");
